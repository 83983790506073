import { defineStore } from 'pinia';
import axiosInstance from './axios.js';

export const useChatsStore = defineStore('chatsStore', {
  state: () => ({
    chats: [],
    loading:false,
    hasMorePages: true,
    lastEvaluatedKey: null,
    limit:25
  }),
  actions: {
    async updateChat(chat_sk, chat) {
      const response = await axiosInstance.put(`/chats/${chat_sk}`, chat);
      if (this.chat && this.chat.sk) this.chat = response.data;
      if (response.data) {
        this.chats = this.chats.map((i) =>
          i.sk === response.data.sk ? response.data : i
        );
      }
      return response.data;
    },
    async deleteChat(chat_sk) {
      const response = await axiosInstance.delete(`/chats/${chat_sk}`);
      this.chats = this.chats.filter((i) => i.sk !== chat_sk);
      if (this.chat && this.chat.sk === chat_sk) {
        this.chat = null;
        this.messages = [];
      }
      return response.data;
    },
    async reload() {
      if (this.loading) return;
      this.chats = [];
      this.hasMorePages = true;
      return await this.list();
    },
    async listChats() {
      if (this.loading) return;
      const params = {
        last_evaluated_key: this.lastEvaluatedKey,
        limit: this.limit
      };
      this.loading = true;
      try {
        const response = await axiosInstance.get('/chats/', {params});
        this.chats = [...response.data.items.reverse(), ...this.chats];
        this.lastEvaluatedKey = response.data.last_evaluated_key;
        return response.data;
      } finally {
        this.loading = false;
      }
    }
  }
})
