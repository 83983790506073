<template>
  <v-main>
    <v-container>
      <v-card flat title="Characters" class="full-height">
        <list>
          <v-list-item v-for="item in media" :key="item" @click="openMedia(item)">
            {{ item }}
          </v-list-item>
        </list>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { useMediaStore } from "@/store/index.js";
import { mapState } from "pinia";

export default {

  mounted() {
    useMediaStore().listVideos()
  },

  computed: {
    ...mapState(useMediaStore, ['media'])
  },

  methods :{
    async openMedia(key) {
      const url = await useMediaStore().getPresignedUrl(key);
      window.open(url, '_blank').focus();
    }
  }
}
</script>