<template>
  <div style="position:relative; overflow: hidden; height:calc(100vh - 65px);" >
    <canvas ref="headerCanvas" height="100%" width="100%"></canvas>
    <div class="text-h6 text-center orbiton h-content">
      <h1 class="mb-5">CTX Vector</h1>
      <router-link to="chats">
        <v-btn>
          Conversations
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {

  name: "StarField",
  data() {
    return {
      stars: [],
      canvas: null,
      ctx: null,
      numStars: 150,
    };
  },
  mounted() {
    this.canvas = this.$refs.headerCanvas;
    this.ctx = this.canvas.getContext('2d');
    this.resizeCanvas();
    window.addEventListener('resize', this.resizeCanvas);
    this.initStars();
    this.animate();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  },
  methods: {
    resizeCanvas() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    },
    initStars() {
      for (let i = 0; i < this.numStars; i++) {
        const x = Math.random() * this.canvas.width;
        const y = Math.random() * this.canvas.height;
        const radius = Math.random() * 1.5;
        const dx = (Math.random() - 0.5) * 0.5;
        const dy = (Math.random() - 0.5) * 0.5;
        this.stars.push({ x, y, radius, dx, dy });
      }
    },
    drawStar(star) {
      this.ctx.beginPath();
      this.ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
      this.ctx.fillStyle = 'white';
      this.ctx.fill();
    },
    updateStar(star) {
      if (star.x > this.canvas.width || star.x < 0) {
        star.dx = -star.dx;
      }

      if (star.y > this.canvas.height || star.y < 0) {
        star.dy = -star.dy;
      }

      star.x += star.dx;
      star.y += star.dy;

      this.drawStar(star);
    },
    animate() {
      requestAnimationFrame(() => this.animate());
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.stars.forEach(star => {
        this.updateStar(star);
      });
    }
  }
};
</script>

<style scoped>
canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.h-content {
  font-family: 'Orbitron', sans-serif; 
  font-size:8em; 
  transform: translate(-50%, -50%); 
  position: absolute; 
  left:50%; 
  top:30%;
}
</style>
