<template>
  <v-app theme="dark">
    
    <v-navigation-drawer v-model="drawer" location="right">
      <NavigationMenu />
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-title>
        <RouterLink :to="{name: 'home'}" stlye="display:inline-block">
          CTXVector
        </RouterLink>
        <v-progress-circular 
        class="ml-2" 
        indeterminate 
        v-if="loading">
        </v-progress-circular>
      </v-app-bar-title>
      <v-spacer />
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-alert 
    v-if="alert" 
    closable 
    :type="alert.type" 
    class="floating-alert" 
    @click="alert.visible=false">
      {{ alert.message }}
    </v-alert>

    <router-view></router-view>

  </v-app>
</template>

<script>
import { useAlertsStore, useRequestStore, useAuthStore } from "@/store/index.js";
import NavigationMenu from "./components/NavigationMenu.vue";
import {mapState} from "pinia"
export default {
  components: {
    NavigationMenu
  },
  created() {
    const auth = useAuthStore();
    auth.init();
  },
  computed:{ 
    ...mapState(useRequestStore, ['loading']),
    ...mapState(useAlertsStore, ['alerts']),
    alert() {
      if (this.alerts.length > 0)
        return this.alerts[this.alerts.length - 1]
      return null;
    }
  },
  data: () => ({ drawer: null }),
}
</script>

<style>
html, body, .v-application__wrap {
  background: linear-gradient(to right, #2b5876, #4e4376);

}
a {
  text-decoration: none;
  color: lightskyblue;
}
.floating-alert {
  position: fixed !important;
  bottom: 0px;
  left:50%;
  transform: translateX(-50%);
  width:100%;
  max-width:500px;
  z-index:1000000;
}
</style>