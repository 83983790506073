import { defineStore } from 'pinia';
import axiosInstance from './axios.js';

export const useMediaStore = defineStore('media', {
  state: () => ({
    media: [],
    loading: false,
    error: null
  }),
  actions: {
    async getPresignedUrl(objectKey) {
      const response = await axiosInstance.get('/media/' + objectKey);
      return response.data;
    },
    async listVideos() {
      const response = await axiosInstance.get('/media/');
      console.log(response)
      this.media = response.data.items;
      return response.data.items;
    }
  }
});
