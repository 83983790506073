import { defineStore } from 'pinia';
import axiosInstance from './axios';

export const useAudioStore = defineStore('audio', {
  state() {
    return {
      isPlayingAudio: false,
      audioPlayer: new Audio()
    }
  },
  actions: {
    stopAudio() {
      this.audioPlayer.pause();
      this.audioPlayer.currentTime = 0;
    },
    pauseAudio() {
      this.audioPlayer.pause();
    },
    playAudio(audioSrc) {
      return new Promise((resolve, reject) => {
        this.pauseAudio();
        this.audioPlayer.volume = 1; 
        this.audioPlayer.setAttribute('crossorigin', "anonymous");
        if (this.audioPlayer.src !== audioSrc) {
          this.audioPlayer.src = audioSrc;
        }
        this.audioPlayer.onplay = () => {
          this.isPlayingAudio = true;
        };
        this.audioPlayer.onpause = () => {
          this.isPlayingAudio = false;
          resolve();
        };
        this.audioPlayer.onended = () => {
          this.isPlayingAudio = false;
          resolve();
        };
        this.audioPlayer.onerror = (e) => {
          this.isPlayingAudio = false;
          reject(e);
        }
        this.audioPlayer.play();
      });
    },
    async textToSpeech(voiceId, text, play=false) {
      const params = {
        "voice_uuid": voiceId,
        "text": text.replace(/\*/g, '').replace(/```(.*?)```/gs, '')
      };
      // run api request and play result
      const response = await axiosInstance.post("/chats/voice", params);
      if (play) {
        await this.playAudio(response.data.item.audio_src);
      }
      return response.data;
    },
    async generateMessageAudio(message, voiceId="1ff0045f") {
      const data = await this.textToSpeech(voiceId, message.content);
      message.audioSrc = data.item.audio_src;
      return data.item.audio_src
    },
    async listVoices() {
      // run api request and play result
      const data = await axiosInstance.get("/chats/voice");
      data.items = data.items.filter(voice => {
        return voice.component_status.text_to_speech.status === "ready" &&
          voice.api_support.sync
      });
      this.voices = data.items;
      return data.items;
    },
  }
});
