<template>
  <v-row 
  no-gutters 
  align-items="middle" 
  justify-content="space-between">
    <v-select 
    @click="loadCharacters"
    :label="label||'Character Profile'"
    :items="characters" 
    v-model="character" 
    item-value="sk"
    item-title="name"
    item-subtitle="description"
    item-props
    >
      <template v-slot:prepend-item>

        <v-list-item title="Create New" link @click="createNewCharacter">
        </v-list-item>

        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
    <v-btn 
    elevation="0"
    icon="mdi-refresh" 
    class="ml-2" 
    @click="getCharacters">
    </v-btn>
  </v-row>
</template>

<script>
import { useCharactersStore } from "@/store/index";
import { mapState } from "pinia";

export default {
  props: ['value', 'label'],
  methods:{ 
    createNewCharacter() {
      this.$router.push({name: 'new-character'});
    },
    loadCharacters() {
      const store = useCharactersStore();
      if (store.characters.length === 0) {
        store.getCharacters();
      }
    },
    getCharacters() {
      useCharactersStore().getCharacters();
    }
  },
  computed: {
    character: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },
    ...mapState(useCharactersStore, ['characters']),
  }
}
</script>