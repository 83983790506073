<template>
  <v-card
  rounded
  variant="tonal"
  v-if="value.recording"
  color="red" 
  style="display: inline;" 
  class="ml-2 text-caption pa-2">
    Recorded
    <v-btn 
    size="x-small"
    elevation="0"
    @click="playRecording" 
    :icon="isPlaying? 'mdi-pause':'mdi-play'"
    >
    </v-btn>
  </v-card>
</template>

<script>
import {useChatRoomStore, useAudioStore } from "@/store/index";
export default {
  props: ['value'],
  data() {
    return {
      isPlaying: false,
      authorizedURL: null
    }
  },
  methods: {
    async getUrl() {
      const message = this.value;
      const chatStore = useChatRoomStore();
      if (!this.authorizedURL) {
        const data = await chatStore.getMessageAudio(message);
        this.authorizedURL = data.url;
        return data.url;
      } else {
        return this.authorizedURL;
      }
    },
    async playRecording() {
      const audio = useAudioStore();
      if (!this.isPlaying) {
        try {
          const url = await this.getUrl();
          this.isPlaying = true
          await audio.playAudio(url);
        } catch(e) {
          console.log(e)
        } finally {
          this.isPlaying = false;
        }
      } else {
        audio.stopAudio()
      }
    }
  }
}
</script>