<template>
  <v-card 
  elevation="0"
  :id="id?id:null"
  :ripple="false"
  class="pa-4 mb-4"
  :class="{'fade-in': controls}"
  width="100%">
    <div 
    style="justify-content: space-between; 
    align-content: center;" 
    class="text-h6 mb-2 text-capitalize d-flex">
      <div class="d-flex" style="align-items:center;">
        <div>
          {{ name }}
          <RecordedAudioTag :value="message" />
        </div>
        <v-progress-circular size="small" v-if="loading || showLoader" indeterminate class="ml-2"></v-progress-circular>
      </div>
      <div class="text-right" v-if="controls">
        <v-btn 
        color="transparent" 
        size="small" 
        elevation="0"
        icon>
          <v-icon>mdi-menu</v-icon>
          <v-menu 
          activator="parent" 
          :close-on-content-click="false">
            <v-list density="compact">
              <v-list-item 
              :disabled="disabled" 
              prepend-icon="mdi-refresh-circle" 
              elevation="0" 
              @click="regenerateMessage">Regenerate</v-list-item>
              <v-divider />
              <v-list-item 
              link
              @click="copyText"  
              prepend-icon="mdi-content-copy" 
              elevation="0">Copy To Clipboard</v-list-item>
              <v-list-item 
              link
              :disabled="disabled" 
              @click="goToRecord"  
              prepend-icon="mdi-record" 
              elevation="0">Record</v-list-item>
              <MessageAudioGenerator :value="message"  :disabled="disabled"/>
              <v-list-item 
              link
              @click="deleteMessage"  
              prepend-icon="mdi-delete" 
              elevation="0">Delete</v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </div>
    </div>
    <MessageArea :value="content" />
  </v-card>
</template>

<script>
import { useChatRoomStore, useAlertsStore } from '@/store/index';
import MessageArea from "./MessageArea.vue";
import MessageAudioGenerator from "./MessageAudioGenerator.vue";
import RecordedAudioTag from "./RecordedAudioTag.vue"
export default {
  mounted() {
    if (this.$route.params.messageId === this.sk) {
      const element = document.getElementById(this.sk);
      if (element)
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
    }
  },
  components:{ MessageArea, MessageAudioGenerator, RecordedAudioTag },
  props: ['value', 'disabled', 'controls', 'showLoader', 'name'],
  computed: {
    id() {return this.message.sk},
    message: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    },
    role: {
      get() {return this.message.role },
      set(value) {this.message.role = value}
    },
    content: {
      get() {return this.message.content},
      set(value) {this.message.content = value}
    },
  },
  data() {
    return {
      loading:false
    }
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.message.content);
    },
    deleteMessage(e) {
      e.stopPropagation();
      const store = useChatRoomStore();
      try {
        this.loading = true;
        store.deleteMessage(this.message);
      } catch {
        const alerts = useAlertsStore();
        alerts.addAlert('Failed to delete message', 'error');
      } finally {
        this.loading = false;
      }
    },
    goToRecord(e) {
      e.stopPropagation();
      const params = {chat_sk: this.message.chat_sk};
      const query = {messageId: this.message.sk};
      e.stopPropagation();
      this.$router.push({name: "chatRecorder", params, query});
    },
    select(e) {
      if (!this.sk) return;
      e.stopPropagation();
      const query = {...this.$route.query, message: this.sk};
      this.$router.push({'name': 'chat', query})
    },
    handleMessageChunk(data) {
      this.message.content = this.message.content + data;
    },
    async regenerateMessage() {
      const currentContent = this.message.content;
      try {
        this.loading = true;
        const store = useChatRoomStore();
        await store.regenerateResponse(this.message, this.handleMessageChunk);
        if (this.message.content !== "") {
          await store.updateMessage(this.message)
        } else {
          this.message.content = currentContent;
        }
      } catch (e) {
        console.log(e)
        this.message.content = currentContent;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>


<style>
.fade-in {
  animation: fade-in 1s;
}
.selected-message {
  background-color: #060140 !important;
}
.text-animate {
  animation: colorchange 3s infinite alternate;
  text-transform: capitalize;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes colorchange {
  0% {
    color: red;
  }
  50% {
    color: blue;
  }
  100% {
    color: green;
  }
}
</style>