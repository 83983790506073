<template>
  <v-main>
    <v-container style="height:100%; width: 800px; max-width: 100%;">
      <v-row style="height:100%;" no-gutters align-content="center">
        <v-col cols="12">
          <v-card min-width="80%" max-width="800">
            <v-card-title class="mb-4 pa-4">
              Confirm Account
            </v-card-title>
            <v-progress-linear class="ml-4" v-if="loading" indeterminate></v-progress-linear>
            <v-card-text>
              <v-form @submit.prevent="confirmAccount" ref="form">
                <v-text-field v-model="email" label="Email Address" required></v-text-field>
                <v-text-field v-model="confirmationCode" label="Confirmation Code" required :rules="emailRules"></v-text-field>
                <v-btn color="primary" type="submit">Confirm</v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <router-link to="/register">
                <v-btn color="secondary">Register</v-btn>
              </router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { useAuthStore } from "@/store/index";
export default {
  data() {
    return {
      loading: false,
      email: '',
      confirmationCode: '',
      valid: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
    };
  },
  methods: {
    async confirmAccount() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const auth = useAuthStore();
          await auth.confirmAccount(this.email, this.confirmationCode);
          this.$router.push('/');
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 400px;
  margin: 0 auto;
}
</style>
