import { createApp } from 'vue';
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia';
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import './registerServiceWorker'

const customTheme = {
  dark: true,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    secondary: '#03DAC6',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    // Add your custom colors here
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "light",
    themes: {
      customTheme,
      light: {
        dark: false,
        colors: {
          background: "#FFFFFF",
          primary: "#ffff00",
          secondary: "#b0bec5",
          accent: "#25316A",
          error: "#E86674",
          orange: "#FF7A0D",
          golden: "#A68C59",
          badge: "#F5528C",
          customPrimary: "#ff0000",
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: "#085294",
        },
      },
    },
  },
})

createApp(App)
  .use(createPinia())
  .use(vuetify)
  .use(router)
  .mount('#app')

