<template>
  <v-toolbar density="compact" color="transparent">
    <v-tabs v-model="tab">
      <v-tab value="ai">AI</v-tab>   
    </v-tabs>
  </v-toolbar>

  <v-tabs-window v-model="tab">

    <v-tabs-window-item value="ai">
      <ChatSettings />
    </v-tabs-window-item>

  </v-tabs-window>
</template>

<script>
import ChatSettings from "./ChatSettings.vue";

export default {
  props: {
    'sk': String
  },
  components: {
    ChatSettings
  },
  data() {
    return {
      tab: 0
    };
  }
};
</script>

