<template>
  <v-main>
    <v-container style="height:100%; width: 800px; max-width: 100%;">
      <v-row style="height:100%;" no-gutters align-content="center">
        <v-col cols="12">
          <v-card min-width="80%" max-width="800">
            <v-card-title class="mb-4 pa-4">
              Register
            </v-card-title>
            <v-progress-linear class="ml-4" v-if="loading" indeterminate></v-progress-linear>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  :rules="emailRules"
                  required
                  :disabled="loading"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  label="Password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="passwordRules"
                  required
                  :disabled="loading"
                ></v-text-field>

                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm Password"
                  prepend-icon="mdi-lock"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  :rules="confirmPasswordRules"
                  required
                  :disabled="loading"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <router-link to="/login">
                <v-btn color="secondary">Login</v-btn>
              </router-link>
              <v-spacer />
              <v-btn
                  :disabled="!valid || loading"
                  color="primary"
                  @click="register"
                >
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { useAuthStore } from '@/store/index.js';
export default {
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      confirmPassword: '',
      valid: false,
      showPassword: false,
      showConfirmPassword: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be at least 6 characters',
      ],
      confirmPasswordRules: [
        v => !!v || 'Confirm Password is required',
        v => v === this.password || 'Passwords do not match',
      ],
    };
  },
  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await useAuthStore().register(this.email, this.password);
          this.$router.push({name: 'login'})
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 400px;
  margin: 0 auto;
}
</style>
../store/api