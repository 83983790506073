<template>
  <v-progress-circular v-if="generatingAudio" indeterminate></v-progress-circular>
  <v-list-item 
  v-else-if="!generatingAudio && !playingAudio" 
  :disabled="disabled" 
  prepend-icon="mdi-account-voice" 
  @click="generateAudio">Text To Speech</v-list-item>
  <v-list-item 
  v-else-if="playingAudio" 
  :disabled="disabled" 
  prepend-icon="mdi-account-voice" 
  color="red" 
  @click="stopAudio">Stop Reading</v-list-item>
</template>

<script>
import { useAlertsStore, useAudioStore } from '@/store/index';
export default {
  props: ['value', 'disabled'],
  data() {
    return {
      generatingAudio:false,
      playingAudio: false
    }
  },
  computed: {
    message() {
      return this.value
    }
  },
  methods: {
    async stopAudio() {
      this.playingAudio = false;
      this.generatingAudio = false;
      const audio = useAudioStore();
      audio.stopAudio();
    },
    async checkIfAudioExists(url) {
      try {
        const response = await fetch(url);
        return response.ok;
      } catch {
        return false
      }
    },
    async generateAudio() {
      try {
        const audio = useAudioStore();
        if (this.message.audioSrc) {
          this.playingAudio = true;
          await audio.playAudio(this.message.audioSrc)
        } else {
          this.generatingAudio = true;
          const src = await audio.generateMessageAudio(this.message);
          this.generatingAudio = false;
          this.playingAudio = true;
          await audio.playAudio(src);
        }
      } catch (e) {
        console.log(e)
        if (this.message.audioSrc && !await this.checkIfAudioExists(this.message.audioSrc)) {
          this.message.audioSrc = null;
          this.generateAudio();
        } else {
          useAlertsStore().addAlert("Failed to create audio for this message");
        }
      } finally {
        this.generatingAudio = false;
        this.playingAudio = false;
      }
    }
  }
}
</script>
