import axiosInstance from "./axios.js";
import { defineStore } from 'pinia';
import { useAlertsStore } from "./alerts.js";

export const useCharactersStore = defineStore('characters', {
  state: () => ({
    characters: [],
  }),

  actions: {
    async getCharacters() {
      try {
        const response = await axiosInstance.get('/characters/');
        this.characters = response.data.items;
      } catch (error) {
        console.log(error);
        useAlertsStore().addAlert('Failed to fetch characters:', 'error');
      }
    },
    async getCharacter(sk) {
      try {
        const response = await axiosInstance.get(`/characters/${sk}`);
        return response.data;
      } catch (error) {
        console.log(error);
        useAlertsStore().addAlert('Failed to fetch character:', 'error');
      }
    },
    async createCharacter(character) {
      try {
        const response = await axiosInstance.post('/characters/', character);
        this.characters = [response.data, ...this.characters];
      } catch (error) {
        console.log(error);
        useAlertsStore().addAlert('Failed to add character:', 'error');
      }
    },
    async updateCharacter(sk, character) {
      try {
        const response = await axiosInstance.put(`/characters/${sk}`, character);
        const index = this.characters.findIndex((c) => c.sk === sk);
        if (index !== -1) {
          this.characters[index] = response.data;
        }
      } catch (error) {
        console.log(error);
        useAlertsStore().addAlert('Failed to update character:', 'error');
      }
    },
    async deleteCharacter(sk) {
      try {
        await axiosInstance.delete(`/characters/${sk}`);
        this.characters = this.characters.filter((c) => c.sk !== sk);
      } catch (error) {
        console.log(error);
        useAlertsStore().addAlert('Failed to delete character:', 'error');
      }
    }
  }
});