<template>
  <v-list lines="two">
    <v-list-item title="CTX Vector" :subtitle="user?user.sub:'Generative Dialog'"></v-list-item>
    <v-divider></v-divider>
    <div v-if="user">
      <RouterLink to="/chats"><v-list-item link title="Conversations"></v-list-item></RouterLink>
      <RouterLink to="/characters"><v-list-item link title="Characters"></v-list-item></RouterLink>
      <RouterLink to="/endpoint"><v-list-item link title="Endpoint"></v-list-item></RouterLink>
      <v-divider />
      <v-list-item link title="Logout" @click="logout"></v-list-item>
    </div>
    <div v-else>
      <RouterLink to="login"><v-list-item link title="Log In"/></RouterLink>
      <RouterLink to="register"><v-list-item link title="Register"/></RouterLink>
    </div>
  </v-list>
</template>

<script>
import {useAuthStore} from "../store/index";
import {mapState} from "pinia";
export default {
  computed: {
    ...mapState(useAuthStore, ['user'])
  },
  methods: {
    logout() {
      useAuthStore().logout();
      this.$router.push({name: "home"})
    }
  }
}
</script>