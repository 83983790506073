<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          Camera Recording
          <v-progress-circular indeterminate v-if="isUploading" class="ml-2"></v-progress-circular>
        </v-card-title>
        <v-card-text>
          <video ref="video" autoplay playsinline width="100%"></video>
        </v-card-text>
        <v-card-actions>
          <v-btn 
          @click="toggleRecord" 
          :text="isRecording? 'Stop Recording': 'Start Recording'"
          ></v-btn>
          <v-spacer />
          <v-btn @click="toggleCamera" icon="mdi-refresh"></v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { S3Client } from '@aws-sdk/client-s3';
import { useAlertsStore, useAuthStore } from '@/store/index.js';
import S3MultipartVideoUploader from "@/store/videoRecorder.js";
import { mapState } from 'pinia';

export default {

  async mounted() {
    this.getUserMedia();
  },

  beforeUnmount() {
    this.$refs.video.srcObject = null;
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  },

  data() {
    return {
      bucketName: 'ctx-vector-video',
      recorder: null,
      stream: null,
      isRecording: false,
      isUploading: false,
      selectedCamera: 'user',
      cameras: {
        user: { facingMode: 'user' },
        environment: { facingMode: 'environment' },
      }
    };
  },

  computed: {
    ...mapState(useAuthStore, ['user'])
  },

  methods: {

    toggleCamera() {
      this.selectedCamera = this.selectedCamera === 'user'? 'environment': 'user';
      const wasRecording = this.isRecording;
      this.getUserMedia();
      if (wasRecording) {
        this.startRecording();
      }
    },

    async getUserMedia() {
      if (this.isRecording) {
        this.stopRecording();
      }
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: true, 
        video: this.selectedCamera === 'user'? this.cameras.environment: this.cameras.user
      });
      this.stream = stream;
      this.$refs.video.srcObject = stream;
    },

    async getCredentials() {
      const auth = useAuthStore();
      return await auth.getAWSIdentityCredentials();
    },

    toggleRecord() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },

    async startRecording() {
      try {
        // Request access to the user's camera
        if (!this.stream) await this.getUserMedia();

        const credentials = await this.getCredentials();

        const s3Client = new S3Client({ region: 'us-west-2', credentials });

        // Create a unique key for this upload
        const key = `${this.user.sub}/video/raw/${new Date().toISOString()}.webm`;

        this.recorder = new S3MultipartVideoUploader(s3Client);

        await this.recorder.initialize(this.stream, this.bucketName, key);
        this.isRecording = true;
        this.recorder.startRecording();

      } catch (err) {
        console.log(err)
      }
    },

    async stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;
        useAlertsStore().addAlert('Finalizing upload', 'info');
        this.isUploading = true;
        await this.recorder.stopRecording();
        this.$emit('newRecording')
        this.isUploading = false;
        useAlertsStore().addAlert('Video successfully saved', 'success');
      }
    },
  }
};
</script>

<style scoped>
video {
  width: 100%;
}
</style>
