<template>
  <v-footer app class="pa-0 mt-5">
    <v-toolbar 
    class="d-flex" 
    style="width:100%;"  
    color="transparent">
      <slot></slot>
      <input 
      autofocus
      class="text-toolbar-field px-2"
      type="text" 
      v-model="text" 
      placeholder="Type Something" 
      @keypress.enter="sendMessage"/>
      <v-btn 
      :disabled="disabled"
      icon="mdi-send"
      color="white"
      class="ml-2" 
      elevation="0" 
      @click="sendMessage" 
      >
      </v-btn>
    </v-toolbar>
  </v-footer>
</template>

<script>
export default {
  props: ['value', 'disabled'],
  data() {
    return {
      text: null
    }
  },
  methods:{
    sendMessage() {
      if (!this.loading) {
        this.$emit('send', this.text);
        this.text = null;
      }
    }
  }
};
</script>

<style>
.text-toolbar {
  position: absolute;
  left:0px;
  bottom: 0px;
  background: rgb(26, 26, 26);
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10000;
  width:100%;
  padding: 5px;
}
.text-toolbar-field {
  width: 100%;
}

</style>
