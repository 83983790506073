import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

export const useAlertsStore = defineStore('alertStore', {
  state: () => ({
    alerts: [],
  }),
  actions: {
    addAlert(message, type = 'info', timeout = 5000) {
      const id = uuidv4();
      const alert = { id, message, type };
      this.alerts.push(alert);
      
      if (timeout) {
        setTimeout(() => {
          this.removeAlert(id);
        }, timeout);
      }
    },
    removeAlert(id) {
      this.alerts = this.alerts.filter(alert => alert.id !== id);
    },
    clearAlerts() {
      this.alerts = [];
    },
  },
});
