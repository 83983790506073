<template>
  <v-main>
    <v-container>
      <v-card flat title="Characters" class="full-height">
        <template v-slot:text>
          <v-toolbar color="transparent" density="compact">
            <v-text-field
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              density="compact"
              single-line
            ></v-text-field>
            <v-btn 
            @click="deleteCharacters"
            :disabled="selected.length === 0"
            text="Delete" 
            color="error" 
            class="ml-2"></v-btn>
            <v-btn color="secondary" @click="newCharacter">
              New
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <v-data-table
          color="transparent"
          :headers="headers"
          :items="characters"
          :search="search"
          show-select
          v-model="selected"
          height="100%"
          item-value="sk"
        >      
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.name="{item}">
            <RouterLink :to="{name:'edit-character', params: {sk: item.sk}}">
            {{item.name}}
            </RouterLink>
          </template>
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.sk="{item}">
            {{formatDate(item.sk)}}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { useCharactersStore, useAlertsStore } from "@/store/index";
import { mapState } from "pinia";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      loading: false,
      selected: [],
      search: '',
      headers: [
        {
          align: 'start',
          key: 'name',
          sortable: false,
          title: 'Name',
          value: "name"
        },
        { key: 'description', title: 'Description' },
        { key: 'sk', title: 'Created', value: "sk" }
      ],
    };
  },
  computed: {
    ...mapState(useCharactersStore, ['characters'])
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
    },
    newCharacter() {
      this.$router.push({name: "new-character"});
    },
    async getCharacters() {
      this.loading = true;
      try {
        await useCharactersStore().getCharacters();
      } catch(e) {
        useAlertsStore().addAlert('Failed to retrieve characters', 'error');
      }finally {
        this.loading = false;
      }
    },
    async deleteCharacters() {
      this.loading = true;
      try {
        if (confirm('Are you sure you want to delete the selected character(s)?')) {
          for (let i = 0; i < this.selected.length; i++) {
            const characterId = this.selected[i];
            await useCharactersStore().deleteCharacter(characterId);
          }
        } else {
          console.log('s')
        }
      } catch(e) {
        console.log(e)
        useAlertsStore().addAlert('Failed to delete selected character(s)', 'error');
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.getCharacters();
  }
}
</script>
