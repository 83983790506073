<template>
  <v-main>
    <v-container style="height:100%; width: 800px; max-width: 100%;">
      <v-row style="height:100%;" no-gutters align-content="center">
        <v-col cols="12">
          <v-card min-width="80%" max-width="800">
            <v-card-title class="mb-4 pa-4">
              Login
            </v-card-title>
            <v-progress-linear class="ml-4" v-if="loading" indeterminate></v-progress-linear>
            <v-card-text>
              
              <v-form ref="form" v-model="valid">
                <v-text-field
                  :disabled="loading"
                  v-model="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  :rules="emailRules"
                  required
                ></v-text-field>

                <v-text-field
                  :disabled="loading"
                  v-model="password"
                  label="Password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="passwordRules"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <router-link to="/register">
                <v-btn color="secondary">Register</v-btn>
              </router-link>
              <v-spacer />
              <v-btn
                  :disabled="!valid || loading"
                  color="primary"
                  @click="login"
                >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { useAuthStore } from "@/store/index";
export default {
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      valid: false,
      showPassword: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters',
      ],
    };
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const auth = useAuthStore();
          await auth.login(this.email, this.password);
          this.$router.push('/');
        } catch (err) {
          if (err?.response?.data?.error === 'UserNotConfirmedException') {
            this.$router.push({name: "confirm-account"})
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 400px;
  margin: 0 auto;
}
</style>
