<template>
  <v-toolbar 
  style="align-items:center;" 
  density="compact" 
  color="transparent" 
  class="d-flex pa-2">
    <slot></slot>
    <v-btn 
    :class="{'animated-record-button': isRecording}"
    @click="toggleRecord" 
    :text="isRecording? 'Stop': 'Record'"
    elevation="0"
    color="red"
    prepend-icon="mdi-record">
    </v-btn>
    <v-btn 
    elevation="0"
    class="ml-2 mr-2"
    :disabled="isRecording"
    @click="togglePlay" 
    :icon="isPlayingAudio? 'mdi-pause':'mdi-play'">
    </v-btn>
    <v-btn 
    elevation="0"
    class="ml-2 mr-2"
    :disabled="!isPlayingAudio"
    @click="stopAudio" 
    icon="mdi-stop">
    </v-btn>
    <canvas 
    ref="visualizer"
    style="margin-bottom:0px; max-height:100%; width:100%;">
    </canvas>
  </v-toolbar>
</template>

<script>
import { audioRecorder, audioStreamVisualizer } from "@/store/audioRecorder.js";
import { useAlertsStore, useChatRoomStore, useAudioStore } from "@/store/index.js";
import { mapState } from "pinia";

export default {
  props: ['value'],
  mounted() {
    this.initializeStream();
    this.visualizer = new audioStreamVisualizer(this.$refs.visualizer);
  },
  beforeUnmount() {
    if (this.visualizer) {
      this.visualizer.stop();
      this.visualizer = null;
    }
    if (this.audioStream) {
      this.audioStream.getTracks().forEach(track => {
          track.stop();
      });
      this.audioStream = null;
    }
    const audio = useAudioStore();
    audio.stopAudio();
  },
  computed: {
    ...mapState(useAudioStore, ['audioPlayer', 'isPlayingAudio']),
    message() {
      return this.value;
    },
    recording() {
      if (this.message)
        return this.message.recording;
      return null;
    }
  },
  watch: {
    message() {
      this.presignedUrl = null;
    }
  },
  data() {
    return {
      presignedUrl:null,
      audioChunks: null,
      audioStream: null,
      isRecording: false,
      recorder: null,
      visualizer: null
    };
  },
  methods: {
    async initializeStream() {
      if (!this.audioStream)
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.visualizer.connectSource(this.audioStream);
      return this.audioStream;
    },
    async togglePlay() {
      const audio = useAudioStore();
      if (this.recorder && this.recorder.state === "recording")
        this.recorder.stop();
      if (this.audioPlayer.paused === true) {
        const chats = useChatRoomStore();
        let url = this.presignedUrl;
        if (!url) {
          const data = await chats.getMessageAudio(this.value);
          url = data.url;
        }
        this.presignedUrl = url;
        await audio.playAudio(url);
      } else {
        audio.pauseAudio();
      }
    },
    async stopAudio() {
      const audio = useAudioStore();
      audio.stopAudio();
    },
    toggleRecord() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    setRecordingTimeout() {
      setTimeout(() => {
        if (this.recorder.state === "recording") {
          const store = useAlertsStore();
          store.addAlert("There is a 120 second limit per recording", 'error')
          this.recorder.stop();
        }
      }, 120000)
    },
    async startRecording() {
      try {
        const stream = await this.initializeStream();
        this.isRecording = true;
        this.recorder = new MediaRecorder(stream);
        this.recorder.start();
        this.setRecordingTimeout();
        this.visualizer.connectSource(stream);
        const blob = await audioRecorder(this.recorder);
        this.isRecording = false;
        this.presignedUrl = null;
        const key = await this.uploadAudioToS3(blob);
        await this.setMessageAudio(key);
      } catch (error) {
        console.error(error)
        alert('Error accessing microphone');
      } finally {
        this.isRecording = false;
      }
    },
    stopRecording() {
      this.recorder.stop();
    },
    async setMessageAudio(key) {
      const store = useChatRoomStore();
      const message = {...this.value, recording:key };
      store.updateMessage(message);
    },
    async uploadAudioToS3(blob) {
      try {
        const store = useChatRoomStore();
        const presignedPostData = await store.authorizeMessageAudioUpload(this.value);

        const formData = new FormData();

        // Append the fields from the presigned POST data
        Object.keys(presignedPostData.fields).forEach(key => {
          formData.append(key, presignedPostData.fields[key]);
        });

        // Append the blob
        formData.append('file', new File([blob], "recording.wav"));

        // Send the form data to S3
        const uploadResponse = await fetch(presignedPostData.url, {
          method: 'POST',
          body: formData
        });

        if (uploadResponse.ok) {
          return presignedPostData.sk
        } else {
          throw new Error('Error uploading audio:', uploadResponse.statusText);
        }
      } catch (error) {
        throw new Error('Error uploading audio:', error);
      }
    }
  }
};
</script>

<style>
@keyframes colorChange {
  0% {
    background: red;
  }
  50% {
    background: transparent;
  }
  100% {
    background: red;
  }
}

/* Apply the animation to the element */
.animated-record-button {
  animation: colorChange 3s infinite alternate !important;
}
</style>