import { defineStore } from 'pinia';

export const useRequestStore = defineStore('requestStore', {
  state: () => ({
    requests: {},
  }),
  getters: {
    loading(state) {
      return Object.values(state.requests).some(request => request.loading);
    },
  },
  actions: {
    addRequest(requestId) {
      this.requests[requestId] = {
        loading: true,
        error: null,
        data: null,
      };
    },
    updateRequestSuccess(requestId, data) {
      this.requests[requestId] = {
        loading: false,
        error: null,
        data: data,
      };
    },
    updateRequestError(requestId, error) {
      this.requests[requestId] = {
        loading: false,
        error: error,
        data: null,
      };
    },
    clearRequest(requestId) {
      delete this.requests[requestId];
    },
  },
});
