<template>
  <v-card elevation="0" title="AI Settings">
    <v-card-text>
      <v-slider 
      thumb-label="always"
      label="Temperature" 
      v-model="temperature"
      :min="0" 
      :max="1"></v-slider>
      <v-slider 
      thumb-label="always"
      label="Frequency Penalty" 
      v-model="frequencyPenalty"
      :min=".1" 
      :max="1" class="mb-5"></v-slider>
    </v-card-text>
  </v-card>
</template>

<script>
import {useChatRoomStore} from "../store/index";

export default {
  computed: {
    temperature: {
      get() {return useChatRoomStore().settings.temperature},
      set(v) {useChatRoomStore().settings.temperature = v.toFixed(2)}
    },
    frequencyPenalty: {
      get() {return useChatRoomStore().settings.frequency_penalty},
      set(v) {useChatRoomStore().settings.frequency_penalty = v.toFixed(2)}
    },
  },
};
</script>

