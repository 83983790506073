<template>
  <v-container
  @click="handleClick" 
  @wheel="stopAutoScroll" 
  @touchstart="stopAutoScroll">
    <ChatMessage 
    v-for="message, i in messages" 
    :name="message.role === 'user'? userName: assistantName"
    :key="'chat-message-'+ i"
    :value="message"
    :disabled="loading"
    :controls="true" />
    <ChatMessage 
    :name="tempMessage.role === 'user'? userName: assistantName"
    :showLoader="true"
    :controls="false"
    v-if="tempMessage"
    :value="tempMessage" />
  </v-container>
  <ChatTextbox 
  :disabled="loading" 
  @send="sendMessage">
    <slot></slot>
  </ChatTextbox>
  <v-dialog :value="true" v-if="loadingNextPage || loading">
    fewjflkwej
    <v-progress-circular indeterminate/>
  </v-dialog>
</template>



<script>
import ChatMessage from "./ChatMessage.vue";
import ChatTextbox from "./ChatTextbox.vue";
import { useAlertsStore, useCharactersStore, useChatRoomStore } from "@/store/index";
import {mapState} from "pinia";

export default {
  props: {
    sk: String
  },
  components:{ 
    ChatTextbox,
    ChatMessage
  },
  data() {
    return {
      tempMessage:null,
      scrollInterval: null,
      loadingNextPage: false
    }
  },
  async created() {
    const store = useChatRoomStore();
    if (!store.chat || store.chat.sk !== this.sk) {
      const store = useChatRoomStore();
      await store.loadChat(this.sk);
      scroll(0, window.scrollMaxY);
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState(useChatRoomStore, ['messages', 'loading', 'chat', 'user', 'assistant']),
    ...mapState(useCharactersStore, ['characters']),
    userName() {
      return this.user?.name || "User"
    },
    assistantName() {
      return this.assistant?.name || "Assistant"
    }
  },
  methods:{
    async handleScroll(e) {
      const scrollTop = e.target.scrollingElement.scrollTop;
      if (!this.loadingNextPage && scrollTop === 0) {
        const msg = this.messages[0];
        const store = useChatRoomStore();
        await store.getMessages();
        const element = document.getElementById(msg.sk);
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    },
    handleClick(e) {
      e.stopPropagation();
      this.stopAutoScroll();
      const query = {...this.$route.query}
      delete query.message;
      this.$router.push({name: 'chat', query })
    },
    stopAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }
    },
    startScroller() {
      if (this.scrollInterval) clearInterval(this.scrollInterval);
      this.scrollInterval = setInterval(() => scroll(0, window.scrollMaxY), 100);
    },
    goToBottom() {
      scroll(0, window.scrollMaxY)
    },
    async handleMessageChunk(data) {
      if (this.tempMessage)
        this.tempMessage.content += data;
    },
    async generateNextMessage() {
      try {
        this.startScroller();
        const store = useChatRoomStore();
        // determine who will respond next
        let role;
        if (this.messages.length > 0) {
          const lastUserRole = this.messages[this.messages.length - 1].role;
          role = lastUserRole === "user"? "assistant": "user";
        } else {
          role = "assistant";
        }
        // create temporary message to append message content to
        this.tempMessage = {'role': role, "content": "", chat_sk: this.sk};
        this.stopAutoScroll();
        await store.generateNewMessage(role, this.handleMessageChunk);
        await this.finalizeMessage();
      } catch (e) {
        console.log(e)
        const msg = "Failed to generate response, check endpoint health"
        useAlertsStore().addAlert(msg, 'error');
      } finally {
        this.tempMessage = null;
        this.stopAutoScroll()
      }
    },
    async finalizeMessage() {
      const store = useChatRoomStore();
      const message = await store.saveMessage(this.tempMessage);
      const query = {...this.$route.query, message: message.sk};
      this.$router.push({ name:'chat', query })
      this.stopAutoScroll();
      this.goToBottom();
    },
    async sendMessage(text) {
      if (this.loading) return;
      if (!text || text.trim() === 0) {
        this.generateNextMessage();
        return;
      }
      try {
        this.startScroller();
        const store = useChatRoomStore();
        const userMessage = {role: 'user', content: text, chat_sk: this.sk};
        await store.saveMessage(userMessage);
        this.text = null;
        this.tempMessage = {role: "assistant", content: "", chat_sk: this.sk}
        this.startScroller();
        await store.generateNewMessage("assistant", this.handleMessageChunk);
        await this.finalizeMessage();
      } catch (e) {
        console.log(e)
        const msg = "Failed to generate response, check endpoint health"
        useAlertsStore().addAlert(msg, 'error');
      } finally {
        this.tempMessage = null;
        this.stopAutoScroll()
      }
    },
  }
};
</script>
