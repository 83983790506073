<template>
  <v-main>
    <v-container>
      <v-card :title="sk?'Update Conversation': 'Create Conversation'">

        <v-card-text>

          <v-progress-linear indeterminate v-if="loading" class="mb-4"></v-progress-linear>

          <v-form ref="form" v-model="valid">

            <v-text-field
              :disabled="loading"
              v-model="chat.title"
              label="Chat Name"
              :rules="nameRules"
              name="name"
              required
              @keyup.enter="saveChat"
            ></v-text-field>

            <v-row no-gutters>

              <v-col cols="12" sm="6">

                <v-card class="mb-4" variant="flat" title="Assistant">
                  <v-card-text>
                    <CharacterSelector 
                      label="Assistant"
                      :value="chat.assistant" 
                      @input="chat.assistant = $event" 
                    />
                    <v-textarea 
                    label="Assistant Instructions" 
                    v-model="chat.assistant_instructions">
                    </v-textarea>
                  </v-card-text>
                </v-card>

              </v-col>

              <v-col cols="12" sm="6">

                <v-card variant="flat" title="User">
                  <v-card-text>
                    <CharacterSelector 
                      label="User"
                      :value="chat.user" 
                      @input="chat.user = $event" 
                    />
                    <v-textarea 
                    label="User Instructions" 
                    v-model="chat.user_instructions">
                    </v-textarea>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-row>

          </v-form>
        
        </v-card-text>

        <v-card-actions>
          <v-btn 
          color="red"
          text="Cancel"  
          @click="cancel" >
          </v-btn>

          <v-spacer />
          <v-btn 
          color="primary"
          text="Save Changes" 
          :disabled="loading || !valid" 
          @click="saveChat" >
          </v-btn>
        
        </v-card-actions>

      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CharacterSelector from "@/components/CharacterSelector"
import { useChatRoomStore } from "@/store/index";

export default {
  components: {
    CharacterSelector
  },
  props: {
    sk: String
  },
  data() {
    return {
      valid: false,
      loading: false,
      nameRules: [
        v => !!v || 'Chat name is required',
        v => (v && v.length <= 100) || 'Chat name must be less than 100 characters'
      ],
      instructionRules: [
        v => !!v || 'This field is required'
      ],
      chat: {}
    };
  },
  methods: {
    itemProps(item) {
      return {
        title: item.name,
        subtitle: item.department,
      }
    },
    cancel() {
      this.sk? this.$router.push({name: 'chat', params: {sk:this.sk}}):
        this.$router.push({name: 'chats'})
    },
    async saveChat() {
      if (!this.valid) return;
      this.loading = true;
      try {
        this.loading = true;
        const store = useChatRoomStore();
        if (this.sk) {
          const chat = await store.updateChat(this.sk, this.chat);
          this.chat = chat;
          this.$router.push({name: 'chat', params: {'sk': this.chat.sk}})
        } else {
          const chat = await store.saveChat(this.chat);
          this.chat = chat;
          this.$router.push({name: 'chat', params: {'sk': this.chat.sk}})
        }
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    if (this.sk) {
      useChatRoomStore().getChat(this.sk)
        .then(chat => {
          this.chat = chat;
        })
        .catch(error => {
            console.error("There was an error:", error.response);
        });
    }
  }
};
</script>
