<template>
  <v-navigation-drawer width="300" v-model="showSidebar">
    <ChatMenu :sk="sk" />
  </v-navigation-drawer >
  <v-main app> 
    <ChatContainer :sk="sk">
      <v-btn 
      @click="showSidebar = !showSidebar"
      class="mr-2" 
      icon="mdi-menu" 
      elevation="0">
      </v-btn>
      <v-btn 
      @click="$router.push({name: 'edit-chat', params: {sk}})"
      class="mr-2" 
      icon="mdi-pencil" 
      elevation="0">
      </v-btn>
    </ChatContainer>
  </v-main>
</template>

<script>
import ChatMenu from "@/components/ChatMenu.vue";
import ChatContainer from "@/components/ChatContainer.vue";

export default {
  props: {
    sk: String
  },
  mounted() {
    if (!this.isMobile) {
      this.showSidebar = true;
    }
  },
  data() {
    return {
      showSidebar: false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile
    }
  },
  components: {
    ChatMenu,
    ChatContainer
  },
};
</script>

<style>

.text-toolbar {
  background: rgb(26, 26, 26);
  position: fixed;
  bottom:0px;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10000;
  width:100%;
  padding: 5px;
}
.text-toolbar-field {
  width: 100%;
}
.chat-container {
  width: 100%;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif !important;
  overflow: scroll;
  height: calc(100vh - 65px);
}
</style>
