<template>
  <v-list lines="two" density="compact">
    <v-list-item
    link
    v-for="message, index in messages"
    :key="'message' + index" 
    class="text-truncate"    
    :active="message.sk === messageId"
    @click="goToMessage(message)">
      <v-list-item-title class="text-capitalize">
        {{message.role}}         
        <v-chip color="red" size="small" v-if="message.recording">
          Recorded
        </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{message.content}}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    messages() {
      return this.value
    },
    messageId() {
      return this.$route.query.messageId
    }
  },
  methods: {
    goToMessage(msg) {
      const {chat_sk, sk} = msg;
      const params = {chat_sk};
      const query = {messageId: sk};
      this.$router.push({name:"chatRecorder", params, query})
    }
  }
}
</script>