import axiosInstance from "./axios.js";
import { useCharactersStore } from "./characters";
import { useChatsStore } from "./chats.js";
import { useEndpointStore } from './runpod.js';
import { defineStore } from 'pinia';
import { defaultPrompt } from "@/prompts/generateCharacter.js";
import endpoint from "./endpoint.js";

export const useChatRoomStore = defineStore('chatRoom', {
  state: () => ({
    chat: null,
    user: null,
    assistant: null,
    messages: [],
    messagesCursor: {
      loading: false,
      hasMorePages: true,
      lastEvaluatedKey: null
    },
    settings: {
      temperature: 0.8,
      frequency_penalty: 0.7,
      max_tokens: 1000,
    },
  }),
  actions: {
    async getCharacter(characterSk) {
      return await useCharactersStore().getCharacter(characterSk);
    },
    async loadChat(chat_sk) {
      this.chat = null;
      this.assistant = null;
      this.user = null;
      this.messages = [];
      this.messagesCursor.loading = false;
      this.messagesCursor.hasMorePages = true;
      this.messagesCursor.lastEvaluatedKey = null;
      const chat = await this.getChat(chat_sk);
      this.chat = chat;
      if (chat.user)
        this.user = await this.getCharacter(chat.user);
      if (chat.assistant)
        this.assistant = await this.getCharacter(chat.assistant);
      await this.getMessages();
      scroll(0, window.scrollMaxY)
    },
    async getChat(chat_sk) {
      const response = await axiosInstance.get(`/chats/${chat_sk}`)
      return response.data;
    },
    async saveChat(chat) {
      const { data } = await axiosInstance.post('/chats/', chat);
      const store = useChatsStore();
      store.chats = [data, ...store.chats];
      return data;
    },
    async updateChat(chat_sk, chat) {
      const response = await axiosInstance.put(`/chats/${chat_sk}`, chat);
      if (response.data.sk && this.chat.sk) this.chat = response.data;
      if (response.data) {
        const store = useChatsStore();
        store.chats = store.chats.map((i) =>
          i.sk === response.data.sk ? response.data : i
        );
      }
      return response.data;
    },
    async deleteChat(chat_sk) {
      const response = await axiosInstance.delete(`/chats/${chat_sk}`);
      const store = useChatsStore();
      store.chats = store.chats.filter((i) => i.sk !== chat_sk);
      if (this.chat && this.chat.sk === chat_sk) {
        this.chat = null;
        this.messages = [];
      }
      return response.data;
    },
    async getMessages() {
      const { lastEvaluatedKey, hasMorePages, loading } = this.messagesCursor;
      if (!hasMorePages || loading) return;
      let url = `/chats/${this.chat.sk}/messages/`;
      if (lastEvaluatedKey) {
        url += "?" + new URLSearchParams({ last_evaluated_key: lastEvaluatedKey });
      }
      this.messagesCursor.loading = true;
      try {
        const response = await axiosInstance.get(url);
        const data = response.data;
        this.messagesCursor.lastEvaluatedKey = data.last_evaluated_key;
        if (!data.last_evaluated_key) {
          this.messagesCursor.hasMorePages = false;
        }
        this.messages = [...data.items.reverse(), ...this.messages];
        return data.items;
      } finally {
        this.messagesCursor.loading = false;
      }
    },
    async saveMessage(message) {
      const response = await axiosInstance.post(`/chats/${message.chat_sk}/messages/`, message);
      this.messages = [...this.messages, response.data.chatMessage];
      return response.data.chatMessage;
    },
    async updateMessage(message) {
      const response = await axiosInstance.put(`/chats/${message.chat_sk}/messages/${message.sk}`, message);
      this.messages = this.messages.map((i) =>
        i.sk === response.data.chatMessage.sk ? response.data.chatMessage : i
      );
      return response.data;
    },
    async deleteMessage(message) {
      const response = await axiosInstance.delete(`/chats/${message.chat_sk}/messages/${message.sk}`);
      this.messages = this.messages.filter((i) => i.sk !== message.sk);
      return response.data;
    },
    async authorizeMessageAudioUpload(message) {
      const response = await axiosInstance.post(`/recordings/${message.chat_sk}/${message.sk}`);
      return response.data;
    },
    async listMessageRecordings(message) {
      const response = await axiosInstance.get(`/recordings/${message.chat_sk}/${message.sk}`);
      return response.data;
    },
    async getMessageAudio(message) {
      console.log(message)
      const response = await axiosInstance.get(`/chats/${message.chat_sk}/messages/${message.sk}/audio/${message.recording}`);
      return response.data;
    },
    async getEndpointId() {
      const store = useEndpointStore();
      if (store.endpoint) {
        return store.endpoint.id
      } else {
        return (await store.getEndpoint()).id;
      }
    },
    async generateNewMessage(role="assistant", chunkHandler) {
      if (this.loading) return;
      const messages = this.prepareMessages(role, this.messages);
      return await this.chatCompletion({ messages, ...this.settings, stream:true }, chunkHandler);
    },
    async regenerateResponse(messageToReplace, chunkHandler) {
      // get index of the message were regenerating
      const msgIndex = this.messages.indexOf(messageToReplace);
      // get prior messages for generating a response
      const previousMessages = this.prepareMessages(
        messageToReplace.role, 
        this.messages.slice(0, msgIndex)
      )
      messageToReplace.content = "";
      messageToReplace.audioSrc = null;
      // send the messages to the completions endpoint to generate the response
      const payload = { messages: previousMessages, ...this.settings, stream:true };
      return await this.chatCompletion(payload, chunkHandler);
    },
    prepareMessages(userRole, messages) {
      let systemPrompt = "";
      const character = this[userRole];
      if (character?.instructions) {
        systemPrompt += "You are the following character:\n\n" + character.instructions + "\n";
      }
      if (this.chat[userRole + '_instructions']) {
        systemPrompt += "Your instructions are:\n\n" + this.chat[userRole + '_instructions'] + '\n';
      }
      let formattedMessages = messages.map(message => {
        if (userRole === "assistant") {
          return { role: message.role, content: message.content };
        } else {
          // flip message roles when the user were generating text for is the user
          return { role: message.role === "assistant"? "user": "assistant", content: message.content };
        }
      });
      formattedMessages = [
        { role: "system", content: systemPrompt + defaultPrompt }, 
        ...formattedMessages
      ];
      return formattedMessages
    },
    async chatCompletion(data, onDataChunk) {
      data = {...data, ...this.settings}
      const endpointId = await this.getEndpointId();
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();

        xhr.open('POST', endpoint + '/chats/completions/' + endpointId, true);
      
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader("Authorization", sessionStorage.getItem('access'))
        xhr.responseType = 'text';
        xhr.timeout = 30000;
      
        let lastPosition = 0;
        let buffer = '';
      
        xhr.onreadystatechange = () => {
          if (xhr.readyState >= 3) {
    
            try {
              // Get the new data from the response
              const newData = xhr.responseText.substring(lastPosition);
              buffer += newData;
              lastPosition = xhr.responseText.length;
      
              let lines = buffer.split('\n\n');
              buffer = lines.pop(); // Keep the last partial line in the buffer
              lines.forEach(line => {
                if (line !== "data: [DONE]")
                  onDataChunk(line.replace("data: ", ""));
                else
                  resolve(xhr.responseText)
              });
            } catch (err) {
              reject(err);
            }
          }
      
          // Finalize processing any remaining buffered data
          if (xhr.readyState === 4) {
            resolve(xhr.responseText);
          }
        };
        // Error handling
        xhr.onerror = () => reject(`An error occurred during your request (${xhr.status})`);
    
        xhr.ontimeout = () => {
          reject('The server took too long to respond');
        }
        // Sending the request
        xhr.send(JSON.stringify(data));
      })
    
    }
    
  }
})