<template>
  <v-main>
    <v-container>
      <v-card :title="sk?'Update Character': 'Create Character'">
        <v-card-text>
          <v-form>
            <v-text-field v-model="character.name" label="Name" required></v-text-field>
            <v-textarea v-model="character.instructions" label="Instructions">
            </v-textarea>
            <v-card title="AI Revisions" subtitle="Use AI to develop a more immersive character" variant="outlined">
              <v-card-text>
                <v-row align-items="middle" justify="space-between" no-gutters>
                  <v-text-field placeholder="Enter details to add to your character" @keyup.enter="generateCharacter" v-model="prompt" label="Add Detail"></v-text-field>
                  <v-btn class="ml-2" @click="generateCharacter" icon="mdi-send"></v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="saveCharacter">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { useCharactersStore, useChatRoomStore } from '@/store/index';
import { 
  characterGeneratorPrompt
} from "@/prompts/generateCharacter";

export default {
  props: {
    sk: String
  },
  data() {
    return {
      prompt: "",
      suggestions: '',
      generatingCharacter: false,
      character: {
        name: '',
        instructions: ''
      }
    }
  },
  methods: {
    handleTextChunk(text) {
      this.character.instructions += text;
    },
    async generateCharacter() {
      if (this.generatingCharacter) return;
      const chat = useChatRoomStore();
      const ogCharacterInstructions = this.character.instructions;
      const userInput = {role: "user", content: this.prompt};
      const sysPrompt = {role: "system", content: characterGeneratorPrompt};
      const instructions = {role: 'user', content: ogCharacterInstructions};
      try {
        this.character.instructions = "";
        const messages = [sysPrompt, instructions, userInput];
        this.generatingCharacter = true;
        await chat.chatCompletion({messages, stream: true}, this.handleTextChunk);
        this.prompt = "";
      } catch(e) {
        console.log(e)
        this.character.instructions = ogCharacterInstructions;
      } finally {
        this.generatCharacter = false
      }
    },
    saveCharacter() {
      if (this.sk) {
        useCharactersStore().updateCharacter(this.sk, this.character)
          .then(() => {
            this.$router.push('/characters');
          })
          .catch(error => {
            console.error("There was an error:", error.response);
          });
      } else {
        useCharactersStore().createCharacter(this.character)
          .then(() => {
            this.$router.push('/characters');
          })
          .catch(error => {
            console.error("There was an error:", error.response);
          });
      }
    }
  },
  created() {
    if (this.sk) {
      useCharactersStore().getCharacter(this.sk)
        .then(character => {
          this.character = character;
        })
        .catch(error => {
            console.error("There was an error:", error.response);
        });
    }
  }
}
</script>
