function rotatingCubeAnimation(ctx) {

  let canvas = ctx.canvas;

  const distance = 2;

  const cube = {
    vertices: [
      [-1, -1, -1], [1, -1, -1],
      [1, 1, -1], [-1, 1, -1],
      [-1, -1, 1], [1, -1, 1],
      [1, 1, 1], [-1, 1, 1]
    ],
    edges: [
      [0, 1], [1, 2], [2, 3], [3, 0],
      [4, 5], [5, 6], [6, 7], [7, 4],
      [0, 4], [1, 5], [2, 6], [3, 7]
    ],
    position: { x: canvas.width / 2, y: canvas.height / 2, z: null },
    size: 0,
    angleX: 0,
    angleY: 0,
    angleZ: 0,
    speedX: .2,
    speedY: .2,
    speedZ: 0,
    rotationSpeedX: 0.01,
    rotationSpeedY: 0.02,
    rotationSpeedZ: 0.01,
    color: "rgba(0, 128, 255, 0.6)"
  };

  function rotate(vertex, angleX, angleY, angleZ) {
    let [x, y, z] = vertex;

    // Rotation around X-axis
    let cosX = Math.cos(angleX);
    let sinX = Math.sin(angleX);
    let y1 = y * cosX - z * sinX;
    let z1 = y * sinX + z * cosX;

    // Rotation around Y-axis
    let cosY = Math.cos(angleY);
    let sinY = Math.sin(angleY);
    let x2 = x * cosY + z1 * sinY;
    let z2 = -x * sinY + z1 * cosY;

    // Rotation around Z-axis
    let cosZ = Math.cos(angleZ);
    let sinZ = Math.sin(angleZ);
    let x3 = x2 * cosZ - y1 * sinZ;
    let y3 = x2 * sinZ + y1 * cosZ;

    return [x3, y3, z2];
  }

  function project(vertex) {
    const [x, y, z] = vertex;
    const scale = cube.size / (z + distance); // Perspective scaling
    return [
      x * scale + cube.position.x,
      y * scale + cube.position.y
    ];
  }

  function drawCube() {

    const projectedVertices = cube.vertices.map(vertex =>
      project(rotate(vertex, cube.angleX, cube.angleY, cube.angleZ))
    );

    ctx.strokeStyle = cube.color;
    ctx.fillStyle = cube.color;

    for (const [start, end] of cube.edges) {
      const [x1, y1] = projectedVertices[start];
      const [x2, y2] = projectedVertices[end];
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    }
  }

  function updateCube() {
    // Update rotation
    cube.angleX += cube.rotationSpeedX;
    cube.angleY += cube.rotationSpeedY;
    cube.angleZ += cube.rotationSpeedZ;
    cube.position.x = canvas.width / 2;
    cube.position.y = canvas.height / 2;
    cube.size = (canvas.height + canvas.width) / 20;
  }

  this.draw = () => {
    updateCube();
    drawCube();
  }

}




function getParentFontColor(element) {
  const parentElement = element.parentElement;
  const computedStyle = window.getComputedStyle(parentElement);
  return computedStyle.color;
}

function drawVisual(canvasCtx, analyser, dataArray) {

  const WIDTH = canvasCtx.canvas.width;
  const HEIGHT = canvasCtx.canvas.height;
  const bufferLength = Math.abs(analyser.frequencyBinCount * .7);

  analyser.getByteFrequencyData(dataArray);

  const barWidth = (WIDTH / bufferLength);
  let value;
  let x = 0;

  let xMultiplier = HEIGHT / 255;

  for (let i = 0; i < bufferLength; i++) {
    let barHeight = value * xMultiplier;
    value = dataArray[i];
    canvasCtx.fillStyle = getParentFontColor(canvasCtx.canvas);
    canvasCtx.fillRect(x, HEIGHT, barWidth, -barHeight);
    x += barWidth + 1;
  }

}

export function audioStreamVisualizer(canvasElement) {
  if (!canvasElement) return;

  let animationFrame;

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  let source;
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 256;
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  const canvasCtx = canvasElement.getContext('2d');
  const cube = new rotatingCubeAnimation(canvasCtx);

  const loop = () => {
    canvasElement.width = canvasElement.clientWidth;
    canvasElement.height = canvasElement.clientHeight;
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    if (source)
      drawVisual(canvasCtx, analyser, dataArray);
    cube.draw();
    animationFrame = window.requestAnimationFrame(loop);
  }

  this.connectSource = (streamable) => {
    this.disconnectSource();
    if (streamable instanceof HTMLAudioElement) {
      source = audioContext.createMediaElementSource(streamable);
      source.connect(audioContext.destination);
    } else {
      source = audioContext.createMediaStreamSource(streamable);
    }
    source.connect(analyser);
  }

  this.disconnectSource = () => {
    if (source) {
      source.disconnect();
      source = null;
    }
  }

  this.stop = () => {
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    window.cancelAnimationFrame(animationFrame);
    if (source) {
      source.disconnect();
    }
    audioContext.close();
  }

  animationFrame = window.requestAnimationFrame(loop);

}

export function audioRecorder(mediaRecorder) {

  return new Promise((resolve) => {

    const audioChunks = [];

    mediaRecorder.addEventListener('dataavailable', event => {
      audioChunks.push(event.data);
    });

    mediaRecorder.addEventListener('stop', () => {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      audioChunks.length = 0;
      resolve(audioBlob);
    });

  });

}