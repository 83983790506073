import { defineStore } from 'pinia';
import { useAlertsStore } from './alerts.js';
import { jwtDecode } from "jwt-decode";
import axiosInstance from "./axios.js";
import AWS from "aws-sdk";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,
    idToken: null,
    refreshToken: null
  }),

  actions: {

    init() {
      this.accessToken = sessionStorage.getItem('access');
      this.idToken = sessionStorage.getItem('id');
      this.refreshToken = sessionStorage.getItem('refresh');
      if (this.idToken) {
        this.startTokenRefresh();
        this.getAWSIdentityCredentials();
      }
    },

    async login(email, password) {
      const response = await axiosInstance.post('/users/login', {username: email, password});
      this.setTokens(response.data);
      this.startTokenRefresh();
      useAlertsStore().addAlert("You've successfully logged in", 'success');
    },

    async register(email, password) {
      const json = await axiosInstance.post('/users/signup', { email, username: email, password });
      useAlertsStore().addAlert("You've successfully registered, check your email address to confirm your account", 'success');
      return json;
    },

    async confirmAccount(email, confirmationCode) {
      const response = await axiosInstance.post('/users/confirm', {
        username: email, confirmation_code: confirmationCode
      });
      return response.data;
    },

    async setTokens({ access_token, id_token, refresh_token }) {
      this.accessToken = access_token;
      sessionStorage.setItem('access', this.accessToken);
      this.idToken = id_token;
      sessionStorage.setItem('id', id_token);
      if (refresh_token) {
        this.refreshToken = refresh_token;
        sessionStorage.setItem('refresh', this.refreshToken);
      }
      await this.getAWSIdentityCredentials();
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    },

    async refreshTokens() {
      if (!this.refreshToken || !this.accessToken) return;
      try {
        const response = await axiosInstance.post('/users/refresh', { 
          refresh_token: this.refreshToken, 
          access_token: this.accessToken 
        });
        this.setTokens(response.data);
      } catch (error) {
        console.error('Token refresh error:', error);
        this.logout();
      }
    },

    startTokenRefresh() {
      const accessTokenExpiration = this.getAccessTokenExpiration;
      if (accessTokenExpiration) {
        const now = Date.now();
        const refreshInterval = accessTokenExpiration - now - 5 * 60 * 1000; // Refresh 5 minutes before expiration
        console.log('setting refresh', refreshInterval / 1000)
        setTimeout(async () => {
          await this.refreshTokens();
          this.startTokenRefresh(); // Restart the refresh cycle
        }, refreshInterval);
      }
    },

    logout() {
      this.accessToken = null;
      this.idToken = null;
      this.refreshToken = null;
      delete axiosInstance.defaults.headers.common['Authorization'];
      sessionStorage.clear();
    },

    async getAWSIdentityCredentials() {
      const response = await axiosInstance.post('/users/identity', { id_token: this.idToken });
      AWS.config.update(response.data);
      console.log(AWS.config)
      return response.data;
    },

  },

  getters: {
    isAuthenticated: (state) => !!state.accessToken,

    getAccessTokenExpiration: (state) => {
      if (!state.accessToken) return null;
      const decoded = jwtDecode(state.accessToken);
      return decoded.exp ? decoded.exp * 1000 : null;
    },

    user: (state) => {
      if (!state.idToken) return null;
      return jwtDecode(state.idToken);
    },
  },
});
