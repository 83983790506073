<template>
  <v-main app>

    <v-navigation-drawer width="300" v-model="showSidebar">
      <div style="margin-bottom: 6em;">
        <MessagesList :value="filteredMessages" />
      </div>
      <v-toolbar color="grey-darken-4" style="position:absolute;bottom:0px; width:100%;">
        <v-btn icon="mdi-account" :active="user" @click="user=!user" class="mr-2"></v-btn>
        <v-btn icon="mdi-brain" :active="assistant" @click="assistant=!assistant" class="mr-2"></v-btn>
        <v-btn icon="mdi-alert-circle" :active="onlyShowPending" @click="onlyShowPending=!onlyShowPending" class="mr-2"></v-btn>
      </v-toolbar>
    </v-navigation-drawer >

    <v-container class="message-card-wrapper">
      <v-card 
      v-if="message" 
      class="text-capitalize no-select">

        <v-card-title class="text-capitalize">
          {{message.role}}
        </v-card-title>

        <v-card-text>

          <v-row 
          no-gutters
          class="pa-2"
          justify-content="space-around"
          style="font-size:20px"
          align-content="center">
              <MessageArea :value="message.content" />
              <div v-for="recording, i in recordings" :key="i">
                <div>
                  <v-btn @click="playRecording(recording)">play</v-btn>
                  {{ new Date(recording.timestamp) }}
                </div>
              </div>
          </v-row>

        </v-card-text>

      </v-card>
    </v-container>


    <v-footer app class="pa-0">
      <VoiceRecorder :value="message"  @newRecording="listMessageAudio">

        <v-menu
        transition="slide-y-transition">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-menu">
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showSidebar = !showSidebar">
              Messages
            </v-list-item>
            <v-list-item @click="goToChat">
              Chat Room
            </v-list-item>
          </v-list>
        </v-menu>

      </VoiceRecorder>
    </v-footer>

  </v-main>
</template>

<script>
import MessageArea from "@/components/MessageArea.vue";
import MessagesList from "@/components/MessagesList.vue";
import VoiceRecorder from "@/components/VoiceRecorder.vue";
import { useChatRoomStore, useAudioStore } from "@/store/index";
import { mapState } from "pinia"
export default {
  props: {
    sk: String
  },
  data() {
    return {
      recordings: [],
      showSidebar: true,
      filter: "show-all",
      onlyShowPending: false,
      user:true,
      assistant:true
    }
  },
  async created() {
    const store = useChatRoomStore();
    if (!store.chat || store.chat.sk !== this.sk) {
      const store = useChatRoomStore();
      await store.loadChat(this.sk);
      await this.listMessageAudio();
    }
  },
  components: {
    VoiceRecorder,
    MessagesList,
    MessageArea
  },
  computed: {
    messageId() {
      return this.$route.query.messageId
    },
    ...mapState(useChatRoomStore, ['messages']),
    message() {
      const message = this.messages.find(i => i.sk === this.$route.query.messageId);
      if (message) return {...message};
      else return null;
    },
    filteredMessages() {
      let messages = this.onlyShowPending? 
        this.messages.filter(i => !i.recording):
        this.messages;
      messages = messages.filter(i => {
        return i.role === "user" && this.user ||
          i.role === 'assistant' && this.assistant
      });
      return messages;
    }
  },
  watch: {
    message() {
      this.recordings = [];
      this.listMessageAudio();
    }
  },
  methods: {
    playRecording(clip) {
      const store = useAudioStore();
      store.playAudio(clip.url);
    },
    async listMessageAudio() {
      const store = useChatRoomStore();
      const audio = await store.listMessageRecordings(this.message);
      this.recordings = audio;
      console.log(audio)
    },
    goToChat() {
      this.$router.push({name: "chat", params: {chat_sk: this.sk}})
    },
    async updateMessage() {
      const store = useChatRoomStore();
      const original = this.messages.find(i => i.sk === this.$route.query.messageId);
      if (original.content !== this.message.content) {
        delete this.message.audioSrc;
      }
      return store.updateMessage(this.message);
    }
  }
}
</script>

<style>
textarea {
  height:100% !important;
}
.no-select {
  user-select: none;
}
.message-editor-main {
  height: calc(100vh - 65px);
  display:flex;
}
.message-card-wrapper {
  display:flex;
  align-items:center;
  justify-content: space-around;
  overflow-y:scroll;
}
</style>