import { CreateMultipartUploadCommand, UploadPartCommand, CompleteMultipartUploadCommand } from '@aws-sdk/client-s3';
import { useRequestStore } from './requests';
import { v4 as uuidv4 } from "uuid";

function replaceFileExtension(filename, newExtension) {
  // Ensure the new extension starts with a dot
  if (!newExtension.startsWith('.')) {
      newExtension = '.' + newExtension;
  }

  // Find the position of the last dot in the filename
  const lastDotPosition = filename.lastIndexOf('.');

  // If there is no dot, just append the new extension
  if (lastDotPosition === -1) {
      return filename + newExtension;
  }

  // Slice the filename to remove the old extension and add the new one
  return filename.slice(0, lastDotPosition) + newExtension;
}

export default class S3MultipartVideoUploader {

  constructor(client) {
    this.uploadSize = 5242880;
    this.client = client;
    this.parts = [];
    this.partNumber = 1;
    this.uploadPromises = [];
    this.buffer = new Uint8Array(0);
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
      this.recorderOptions = { mimeType: 'video/webm;codecs=vp8,opus'};
      this.fileExtension = '.webm';
    } else if (MediaRecorder.isTypeSupported('video/mp4;codecs=avc1.42E01E,mp4a.40.2')) {
      this.recorderOptions = { mimeType: 'video/mp4;codecs=avc1.42E01E,mp4a.40.2'};
      this.fileExtension = '.mp4';
    } else {
      throw new Error ('No supported mime types found for MediaRecorder');
    }
  }

  async initialize(stream, bucketName, objectKey) {
    const requestStore = useRequestStore();
    const requestId = uuidv4();
    this.stream = stream;
    objectKey = replaceFileExtension(objectKey, this.fileExtension);
    try {
      const createCommand = new CreateMultipartUploadCommand({
        Bucket: bucketName,
        Key: objectKey,
      });
      this.bucketName = bucketName;
      this.objectKey = objectKey;
      requestStore.addRequest(requestId);
      const createResponse = await this.client.send(createCommand);
      requestStore.clearRequest(requestId);
      this.uploadId = createResponse.UploadId;
      this.mediaRecorder = new MediaRecorder(this.stream, this.recorderOptions);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.uploadPromises.push(this.handleChunk(event))
        }
      }
    } catch (e) {
      requestStore.clearRequest(requestId);
      throw new Error('Failed to initialize upload: ' + e)
    }
  }

  async uploadPart(buffer) {
    const requestStore = useRequestStore();
    const requestId = uuidv4();
    const partNumber = this.partNumber;
    try {
      this.partNumber += 1;
      const uploadCommand = new UploadPartCommand({
        Bucket: this.bucketName,
        Key: this.objectKey,
        PartNumber: partNumber,
        UploadId: this.uploadId,
        Body: buffer,
      });
      requestStore.addRequest(requestId);
      const uploadResponse = await this.client.send(uploadCommand);
      requestStore.clearRequest(requestId);
      console.log(`Part ${partNumber} upload response:`, uploadResponse);
      // Save part details for completing the upload
      this.parts.push({
        PartNumber: partNumber,
        ETag: uploadResponse.ETag,
      });
    } catch (error) {
      requestStore.clearRequest(requestId);
      console.error(`Error uploading part ${partNumber}:`, error);
    }
  }

  async handleChunk(event) {
    const arrayBuffer = await event.data.arrayBuffer();

    // Append the new chunk to the buffer
    const newBuffer = new Uint8Array(this.buffer.byteLength + arrayBuffer.byteLength);
    newBuffer.set(new Uint8Array(this.buffer), 0);
    newBuffer.set(new Uint8Array(arrayBuffer), this.buffer.byteLength);
    this.buffer = newBuffer;

    // Upload 5MB chunks
    while (this.buffer.byteLength >= this.uploadSize) {
      const partBuffer = this.buffer.slice(0, this.uploadSize);
      this.buffer = this.buffer.slice(this.uploadSize);
      await this.uploadPart(partBuffer);
    }
  }

  async finalizeUpload() {
    console.log('Waiting for multipart upload to finish')
    // Upload any remaining buffer as the last part
    if (this.buffer.byteLength > 0) {
      const upload = this.uploadPart(this.buffer);
      this.uploadPromises.push(upload);
    }
    await Promise.all(this.uploadPromises);

    console.log('Finalizing upload')
    const requestStore = useRequestStore();
    const requestId = uuidv4();
    try {
      // Sort parts by PartNumber
      this.parts.sort((a, b) => a.PartNumber - b.PartNumber);

      // Check for missing ETags
      if (this.parts.some(part => !part.ETag)) {
        console.error('One or more parts are missing ETags');
        return;
      }

      console.log('Completing multipart upload with parts:', JSON.stringify(this.parts));

      const params = {
        Bucket: this.bucketName,
        Key: this.objectKey,
        UploadId: this.uploadId,
        MultipartUpload: {
          Parts: this.parts,
        },
      };

      // Complete the multipart upload
      const completeCommand = new CompleteMultipartUploadCommand(params);
      requestStore.addRequest(requestId);
      await this.client.send(completeCommand);
      requestStore.clearRequest(requestId);
      console.log('Multipart upload completed successfully');
      // remove references from memory
      delete this.parts;
      delete this.partNumber;
      delete this.uploadPromises;
      delete this.buffer;
      delete this.mediaRecorder;
      delete this.stream;
    } catch (error) {
      requestStore.clearRequest(requestId);
      console.error('Error completing multipart upload:', error);
    }
  }

  async startRecording() {
    this.mediaRecorder.start(1000)
  }

  async stopRecording() {
    this.mediaRecorder.stop();
    await this.finalizeUpload();
  }

}
