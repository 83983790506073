<template>
  <v-main class="home">
    <v-container>
      <HelloWorld />
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
