import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/index';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import ConfirmAccountView from "@/views/ConfirmAccountView.vue";
import RegisterView from '@/views/RegisterView.vue';
import ChatRoomView from "@/views/ChatRoomView.vue";
import ChatsListView from "@/views/ChatsListView.vue";
import ChatFormView from '@/views/ChatFormView.vue';
import MessageRecorderView from "../views/MessageRecorderView.vue";
import EndpointManagementView from "@/views/EndpointManagementView.vue";
import CharacterListView from '@/views/CharacterListView.vue';
import CharacterFormView from '@/views/CharacterFormView.vue';
import VideoRecorderView from '@/views/VideoRecorderView.vue';
import MediaListView from "@/views/MediaListView.vue";

const requiresAuth = true;

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/login', name: 'login', component: LoginView },
  { path: '/register', name: 'register', component: RegisterView },
  { path: "/confirm-account", name: "confirm-account", component: ConfirmAccountView },
  { path: '/chat/:sk', name: 'chat', component: ChatRoomView, meta: { requiresAuth }, props: true },
  { path: "/chat/:sk/record", name: 'chatRecorder', component: MessageRecorderView, meta: {requiresAuth}, props: true },
  { path: '/chats', name: 'chats', component: ChatsListView, meta: { requiresAuth } },
  { path: '/chat/new', name: 'new-chat', component: ChatFormView, meta: { requiresAuth } },
  { path: '/chat/edit/:sk', name: 'edit-chat', component: ChatFormView, props: true, meta: { requiresAuth } },
  { path: '/endpoint', name: "endpoint", component: EndpointManagementView, meta: { requiresAuth }},
  { path: '/characters', name: 'characters', component: CharacterListView, meta: { requiresAuth } },
  { path: '/character/new', name: 'new-character', component: CharacterFormView, meta: { requiresAuth } },
  { path: '/character/edit/:sk', name: 'edit-character', component: CharacterFormView, props: true, meta: { requiresAuth } },
  { path: '/video/record', name: "recording", component: VideoRecorderView, props: true, meta: { requiresAuth } },
  { path: '/media', name: "media", component: MediaListView, props: true, meta: { requiresAuth } }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.matched.some(record => record.meta.requiresAuth)) {
      // This route requires authentication, check if logged in
      if (!authStore.isAuthenticated) {
          // Not authenticated, redirect to login page
          next({ name: 'login', query: {redirect: from.path.name} });
      } else {
          // Authenticated, allow access
          next();
      }
  } else {
      // No authentication required, allow access
      next();
  }
});

export default router
