/* eslint-disable */
<template>
  <v-main>
    <v-container>
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-card flat title="Conversations" class="full-height">

        <template v-slot:text>
          <v-toolbar color="transparent" density="compact">
            <v-text-field
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              density="compact"
              single-line
            ></v-text-field>
            <v-btn 
            @click="deleteSelected"
            :disabled="selected.length === 0"
            text="Delete" 
            color="error" 
            class="ml-2"></v-btn>
            <v-btn color="secondary" @click="$router.push({name:'new-chat'})">
              New
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <v-data-table
          color="transparent"
          :headers="headers"
          :items="chats"
          :search="search"
          show-select
          v-model="selected"
          height="100%"
          item-value="sk"
        >      
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.title="{item}">
            <router-link :to="{name: 'chat', params: {sk: item.sk}}">
            {{item.title || 'Unnamed Chat'}}
            </router-link>
          </template>
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.sk="{item}">
            {{formatDate(item.sk)}}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { DateTime } from "luxon";
import { useChatRoomStore, useChatsStore } from "@/store/index";
import { mapState } from "pinia";
export default {
  data() {
    return {
      editing: null,
      loading: false,
      selected: [],
      search: '',
        headers: [
          { key: 'title', sortable: false, title: 'Title'},
          { key: 'assistant_instructions', title: 'Assistant' },
          { key: 'user_instructions', title: 'User' },
          { key: 'id', title: 'Created', value: "id" }
        ],
    };
  },
  computed: {
    ...mapState(useChatsStore, ['chats']),
    ...mapState(useChatRoomStore, ['chat']),
  },
  methods: {
    goToItemPage(chat) {
      this.$router.push({name: 'chat', params: {chat_sk: chat.sk}})
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
    },
    async loadChats() {
      this.loading = true;
      try {
        const store = useChatsStore();
        store.chats = [];
        await store.listChats();
      } finally {
        this.loading = false;
      }
    },
    async deleteSelected() {
      if (confirm('Are you sure you want to delete the selected chats?'))
      try {
        for (var i = 0; i < this.selected.length; i++) {
          await this.deleteChat(this.selected[i]);
        }
      } finally {
        this.loadChats();
      }
    },
    async deleteChat(chat_sk) {
      this.loading = true;
      try {
        await useChatRoomStore().deleteChat(chat_sk);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadChats();
  }
};
</script>