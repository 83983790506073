<template>
  <div v-for="block, blockI in blocks" :key="id + '-' + blockI">
    <div v-if="block.type === 'text'" v-html="block.content"></div>
    <v-card v-else class="pa-2" variant="outlined" style="overflow: scroll;">
      <pre><code v-html="block.content"></code></pre>
    </v-card>
  </div>
</template>

<script>
import {v4 as uuid} from "uuid";
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-handlebars.min.js';
import 'prismjs/components/prism-lua.min.js';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-markup-templating.js';
import 'prismjs/components/prism-bash.js';

Prism.highlightAll();

export default {
  data() {
    return {
      id: uuid()
    }
  },
  props: ['value'],
  computed: {
    blocks() {
      return this.value.split(/```/)
        .map(paragraph => {
        const lines = paragraph.split(/\n/);
        if (Prism.languages[lines[0].trim()]) {
          const lastIndex = lines.length - 1;
          lines[lastIndex] = lines[lastIndex].replace(/```$/, '')
          const lang = lines[0].replace(/^`/, '').trim();
          const block = {
            type: "code", 
            lang,
            content: Prism.highlight(
              lines.slice(1).join('\n'), 
              Prism.languages[lang], 
              lang
            )
          };
          return block
        } else {
          return {type: "text", content: lines.map(line => {
            return line.replace(/<([^>]+)>|\[([^\]]+)\]\(([^)]+)\)|\*\*(.*?)\*\*|\*(.*?)\*/g, function(match, p1, p2, p3, p4, p5) {
                if (p1) {
                    return '<a href="' + p1 + '" target="_blank" rel="noopener noreferrer">' + p1 + '</a>';
                } else if (p2) {
                    return '<a href="' + p3 + '" target="_blank" rel="noopener noreferrer">' + p2 + '</a>';
                } else if (p4) {
                    return '<span class="text-h6">' + p4 + '</span>';
                } else if (p5) {
                    return '<span class="text-animate">' + p5 + '</span>';
                }
            });
          }).join('<br/>')};
        }
      });
    }
  }
}
</script>


<style>
.no-select {
  user-select:none;
}
.text-animate {
  animation: colorchange 3s infinite alternate;
  text-transform: capitalize;
}

@keyframes colorchange {
  0% {
    color: red;
  }
  50% {
    color: blue;
  }
  100% {
    color: green;
  }
}
</style>