import { defineStore } from 'pinia';
import axiosInstance from "./axios.js";

export const useEndpointStore = defineStore('endpoint', {
  state: () => ({
    endpoint: null,
    health: null,
  }),
  actions: {
    async createEndpoint() {
      try {
        const response = await axiosInstance.post('/runpod/endpoint');
        const data = response.data;
        this.endpoint = data.endpoint;
        this.health = data.health;
        return data;
      } catch (error) {
        console.error("Failed to create endpoint:", error);
        throw error;
      }
    },
    async deleteEndpoint() {
      try {
        const response = await axiosInstance.delete('/runpod/endpoint');
        const data = response.data;
        this.endpoint = null;
        this.health = null;
        return data;
      } catch (error) {
        console.error("Failed to delete endpoint:", error);
        throw error;
      }
    },
    async getHealth() {
      try {
        const response = await axiosInstance.get('/runpod/endpoint/health_check');
        const data = response.data;
        this.health = data.health;
        return data;
      } catch (error) {
        console.error("Failed to get health check:", error);
        this.health = null;
        throw error;
      }
    },
    async getEndpoint() {
      try {
        const response = await axiosInstance.get('/runpod/endpoint');
        const data = response.data;
        this.endpoint = data.endpoint;
        this.health = data.health;
        return data.endpoint;
      } catch (error) {
        console.error("Failed to get endpoint:", error);
        this.endpoint = null;
        this.health = null;
        throw error;
      }
    },
  },
});
