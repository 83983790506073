import axios from 'axios';
import { useAuthStore } from "./auth.js";
import { useRequestStore } from './requests.js';
import { v4 as uuidv4 } from 'uuid';
import { useAlertsStore } from './alerts.js';
import endpoint from "./endpoint.js";

const axiosInstance = axios.create({
  baseURL: endpoint,
});

axiosInstance.interceptors.request.use((config) => {
  const requestId = uuidv4();
  config.metadata = { requestId };
  const requestStore = useRequestStore();
  requestStore.addRequest(requestId);
  const token = useAuthStore().accessToken;
  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { requestId } = response.config.metadata;
    const requestStore = useRequestStore();
    if (response.data.message) {
      useAlertsStore().addAlert(response.data.message, response.status === 200? 'success': 'error')
    }
    requestStore.clearRequest(requestId, response.data);
    return response;
  },
  (error) => {
    const { requestId } = error.config.metadata;
    const requestStore = useRequestStore();
    if (error?.response?.data?.message)
      useAlertsStore().addAlert(error.response.data.message, 'error')
    requestStore.updateRequestError(requestId, error.message);
    return Promise.reject(error);
  }
);

export default axiosInstance;
