<template>  
<v-main>
  <v-container>
    <v-card title="Deployment">
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-card-text>
        <v-row no-gutters style="margin-bottom:50px">
          <v-col class="mb-4" cols="12">
            <v-btn 
              elevation="0"
              :disabled="loading"
              v-if="endpoint"
              block 
              text="Kill Server" 
              color="red" 
              @click="deleteEndpoint">
            </v-btn>
            <v-btn 
              elevation="0"
              v-if="!endpoint"
              :disabled="loading"
              block 
              text="Start Server" 
              color="primary" 
              @click="createEndpoint">
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="health">
            <h2 class="mb-4">
              Server Information
              <v-btn @click="getHealth" icon="mdi-refresh" elevation="0" class="ml-4" text="Reload" size="small"></v-btn>
            </h2>
          </v-col>
          <v-col cols="6">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">
                    Workers
                  </th>
                  <th class="text-left">
                    No.
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in workers"
                  :key="item.name"
                >
                  <td>{{ item.status }}</td>
                  <td>{{ item.number }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
          <v-col cols="6">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">
                    Jobs
                  </th>
                  <th class="text-left">
                    No.
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in jobs"
                  :key="item.name"
                >
                  <td>{{ item.status }}</td>
                  <td>{{ item.number }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</v-main>
</template>

<script>
import { mapState } from "pinia";
import { useAlertsStore, useEndpointStore } from "@/store/index";
export default {
  mounted() {
    this.getEndpoint();
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(useEndpointStore, ['endpoint', 'health']),
    workers() {
      if (this.health)
        return Object.entries(this.health.workers)
          .map((k) => ({status:k[0], number:k[1]}))
      else
        return []
    },
    jobs() {
      if (this.health)
        return Object.entries(this.health.jobs)
          .map((k) => ({status:k[0], number:k[1]}))
      else
        return []
    }
  },
  methods: {
    async getHealth() {
      this.loading = true;
      try {
        await useEndpointStore().getHealth()
      } finally {
        this.loading = false
      }
    },
    async getEndpoint() {
      this.loading = true;
      try {
        await useEndpointStore().getEndpoint()
      } catch (e) {
        if (e.response.status === 404)
          useAlertsStore().addAlert("Endpoint is not running", 'error')
      } finally {
        this.loading = false
      }
    },
    async createEndpoint() {
      this.loading = true;
      try {
        await useEndpointStore().createEndpoint()
      } finally {
        this.loading = false
      }
    },
    async deleteEndpoint() {
      if (confirm("Are you sure you want to stop the server?")) {
        await useEndpointStore().deleteEndpoint()
      }
    }
  }
}
</script>